<template>
    <v-container fluid>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Codigo</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="coupon.coupon_code" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Descripcion</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-textarea label="" v-model="coupon.description" required outlined dense rows="6"
                            row-height="6">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Monto de descuento</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="coupon.coupon_amount" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>


        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Inicia en</v-subheader>
                    </v-col>
                    <v-col cols="5">
                        <v-menu v-model="coupon.menu" :close-on-content-click="false" readonly
                            transition="scale-transition" max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="start_date" v-model="coupon.start_date" append-icon="mdi-calendar"
                                    outlined label="" readonly dense persistent-hint v-on="on" v-bind="attrs" />
                            </template>
                            <v-date-picker v-model="coupon.date" no-title @input="
    coupon.menu = false;
coupon.start_date = formatDate(coupon.date);
                            " />
                        </v-menu>
                    </v-col>
                    <v-col col="4">
                        <v-menu ref="menu2" v-model="coupon.menu2" :close-on-content-click="false" readonly
                            transition="scale-transition" :return-value.sync="coupon.start_time" offset-y
                            max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="start_time" v-model="coupon.start_time"
                                    append-icon="mdi-clock-time-four-outline" outlined label="" readonly dense
                                    persistent-hint v-on="on" v-bind="attrs" />
                            </template>
                            <v-time-picker v-if="coupon.menu2" v-model="coupon.start_time" full-width
                                @click:minute="$refs.menu2.save(coupon.start_time)"></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Termina en </v-subheader>
                    </v-col>
                    <v-col cols="5">
                        <v-menu v-model="coupon.menu3" :close-on-content-click="false" readonly
                            transition="scale-transition" max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="end_date" v-model="coupon.end_date" append-icon="mdi-calendar"
                                    outlined label="" readonly dense persistent-hint v-on="on" v-bind="attrs" />
                            </template>
                            <v-date-picker v-model="coupon.date" no-title @input="
    coupon.menu3 = false;
coupon.end_date = formatDate(coupon.date);
                            " />
                        </v-menu>
                    </v-col>
                    <v-col col="4">
                        <v-menu ref="menu4" v-model="coupon.menu4" :close-on-content-click="false" readonly
                            transition="scale-transition" :return-value.sync="coupon.end_time" offset-y
                            max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="end_time" v-model="coupon.end_time"
                                    append-icon="mdi-clock-time-four-outline" outlined label="" readonly dense
                                    persistent-hint v-on="on" v-bind="attrs" />
                            </template>
                            <v-time-picker v-if="coupon.menu4" v-model="coupon.end_time" full-width
                                @click:minute="$refs.menu4.save(coupon.end_time)"></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>


        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Estado</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-switch v-model="coupon.status" label="" hide-details color="primary"></v-switch>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { required } from "vuelidate/lib/validators";
import mixins from "@/mixins";

export default {
    name: "form-coupon",
    props: ["coupon"],
    watch: {},
    validations() {
        return {
            coupon: {
            },
        };
    },
    data: () => ({
    }),
    computed: {
        // ...mapState({
        //     selectTeams: (state) => state.team.selectTeams,
        // }),
    },
    methods: {
        // ...mapActions(["fetchAllTeams"]),
        getFetch() {
            // this.fetchAllTeams();
        },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style scoped>
/* .row {
    margin-top: -10px !important;
} */
</style>